import {createRouter, createWebHashHistory} from 'vue-router';
import App from './App.vue';

const routes = [
	{
		path: '/',
		name: 'app',
		component: App,
		children: [

			{
				path: '/ajuda',
				name: 'ajuda',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/pages/Ajuda.vue')
			},
			{
				path: '/alertas',
				name: 'alertas',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/alertas/AlertasList.vue')
			},

			{
				path: '',
				name: 'dashboard',
				exact: true,
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/dashboard/DashVendas.vue')
			},
			{
				path: '/analytics',
				name: 'dashboardanalytics',
				beforeEnter : guardMyroute,
				component: () => import('./components/DashboardAnalytics.vue')
			},

			{
				path: '/clientes',
				name: 'clientes',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/clientes/ClientesList.vue')
			},

			{
				path: '/clientedetalhe/:id',
				name: 'clientedetalhe',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/clientes/ClienteDetalhe.vue')
			},

			{
				path: '/condicoes',
				name: 'condicoes',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/condicoes/CondicoesList.vue')
			},

			{
				path: '/formas',
				name: 'formas',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/formas/FormasList.vue')
			},

			{
				path: '/metas',
				name: 'metas',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/metas/MetasList.vue')
			},

			{
				path: '/pedidos',
				name: 'pedidos',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/pedidos/PedidosList.vue')
			},

			{
				path: '/pedidodetalhe/:id',
				name: 'pedidodetalhe',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/pedidos/PedidoDetalhe.vue')
			},

			{
				path: '/pedtipos',
				name: 'pedtipos',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/pedtipos/PedTiposList.vue')
			},

			{
				path: '/produtos',
				name: 'produtos',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/produtos/ProdutosList.vue')
			},

			{
				path: '/produtodetalhe/:id',
				name: 'produtodetalhe',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/produtos/ProdutoDetalhe.vue')
			},

			{
				path: '/usuarios',
				name: 'usuarios',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/usuarios/UsuariosList.vue')
			},

			{
				path: '/usuariodetalhe/:id',
				name: 'usuariodetalhe',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/usuarios/UsuarioDetalhe.vue')
			},


			// ATE AQUI O SENHOR NOS GUIOU


			{
				path: '/leads',
				name: 'leads',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/leads/LeadsList.vue')
			},


			{
				path: '/orcamentos',
				name: 'orcamentos',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/orcamentos/OrcamentosList.vue')
			},


			{
				path: '/orcamentopreview',
				name: 'orcamentopreview',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/orcamentos/OrcamentoPreview.vue')
			},

			{
				path: '/orcamentodetalhe/:id',
				name: 'orcamentodetalhe',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/orcamentos/OrcamentoDetalhe.vue')
			},

			{
				path: '/vendas',
				name: 'vendas',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/vendas/VendasList.vue')
			},


			{
				path: '/vendapreview',
				name: 'vendapreview',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/vendas/VendaPreview.vue')
			},

			{
				path: '/vendadetalhe',
				name: 'vendadetalhe',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/vendas/VendaDetalhe.vue')
			},


			{
				path: '/projetos',
				name: 'projetos',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/projetos/ProjetosList.vue')
			},
			{
				path: '/projetopreview',
				name: 'projetopreview',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/projetos/ProjetoPreview.vue')
			},
			{
				path: '/projetodetalhe',
				name: 'projetodetalhe',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/projetos/ProjetoDetalhe.vue')
			},


			{
				path: '/obras',
				name: 'obras',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/obras/ObrasList.vue')
			},
			{
				path: '/obrapreview',
				name: 'obrapreview',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/obras/ObraPreview.vue')
			},
			{
				path: '/obradetalhe',
				name: 'obradetalhe',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/obras/ObraDetalhe.vue')
			},


			{
				path: '/visitas',
				name: 'visitas',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/visitas/VisitasList.vue')
			},
			{
				path: '/calendario',
				name: 'calendario',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/Calendario.vue')
			},
			{
				path: '/financeiro',
				name: 'financeiro',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/financeiros/FinanceirosList.vue')
			},
			{
				path: '/receitas',
				name: 'receitas',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/financeiros/ReceitasList.vue')
			},
			{
				path: '/despesas',
				name: 'despesas',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/financeiros/DespesasList.vue')
			},
			{
				path: '/comissoes',
				name: 'comissoes',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/financeiros/ComissoesList.vue')
			},

			{
				path: '/pedidosemaberto',
				name: 'pedidosemaberto',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/relatorios/PedidosEmAberto.vue')
			},
			{
				path: '/pedidosporcliente',
				name: 'pedidosporcliente',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/relatorios/PedidosPorCliente.vue')
			},
			{
				path: '/pedidosporperiodo',
				name: 'pedidosporperiodo',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/relatorios/PedidosPorPeriodo.vue')
			},
			{
				path: '/carteiraclientes',
				name: 'carteiraclientes',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/relatorios/CarteiraClientes.vue')
			},
			{
				path: '/vendascolab',
				name: 'vendascolab',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/relatorios/VendasColaborador.vue')
			},
			{
				path: '/apuracomiss',
				name: 'apuracomiss',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/relatorios/ApuracaoComissoes.vue')
			},

			{
				path: '/metasdata',
				name: 'metaspordata',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/relatorios/MetasData.vue')
			},


			{
				path: '/acessorestrito',
				name: 'acessorestrito',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/pages/AcessoBloqueado.vue')
			},

			{
				path: '/testes',
				name: 'testes',
				beforeEnter : guardMyroute,
				component: () => import('./components_esol/testes/Testes.vue')
			}
		]
	},
	{
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    },
	{ path: '/:pathMatch(.*)*', component: () => import('./pages/NotFound.vue') }
];

function guardMyroute(to, from, next)
{
	// console.log('gradMyRoute mounted')
	// console.log(App.store.getters.isAuthenticated)
	if (localStorage.getItem('token')){
		next();
	}else{
		next('/login');
	}
}

const router = createRouter({
    history: createWebHashHistory(),
    routes,
	scrollBehavior () {
        return { left: 0, top: 0 };
    }
});





export default router;
