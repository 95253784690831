
<template>
	<div :class="containerClass" @click="onDocumentClick">
		<AppTopBar :layoutColor="layoutColor" :activeTopbarItem="activeTopbarItem" :topbarMenuActive="topbarMenuActive"
			@menubutton-click="onMenuButtonClick" @topbar-menu-click="onTopbarMenuClick" @topbar-submenu-click="onTopbarSubmenuClick"
			@menuitem-click="onMenuItemClick" @topbar-item-click="onTopbarItemClick" @topbar-menuitem-click="onTopbarMenuItemClick"
      @search-item-click="onSearchItemClick"
      @user-data-changed="onUserDataChanged"
    >

    </AppTopBar>

		<AppMenu :model="menu" :menuMode="menuMode" :active="menuActive" :mobileMenuActive="staticMenuMobileActive"
			@menu-click="onMenuClick" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"></AppMenu>

		<div class="layout-content-wrapper">
			<div class="layout-content">
				<router-view/>
			</div>

			<AppFooter :layoutColor="layoutColor" />

		</div>

		<AppConfig :menuMode="menuMode" :layoutColor="layoutColor" :lightMenu="lightMenu" :theme="theme" :themes="themeColors"
			@menu-mode-change="onMenuModeChange" @color-scheme-change="onColorSchemeChange" @layout-color-change="onLayoutColorChange"
			@theme-change="onThemeChange"></AppConfig>
	</div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import AppFooter from './AppFooter.vue';
import EventBus from './event-bus';
import AppConfig from "./AppConfig";

export default {
	data() {
		return {
			menuMode: 'slim',
      userData: null,
			theme: 'indigo',
			layoutColor: 'white',
			tempLayoutColor: 'white',
			lightMenu: true,
			staticMenuMobileActive: false,
			staticMenuDesktopInactive: false,
			menuClick: false,
			topbarMenuClick: false,
			topbarMenuActive: false,
			activeTopbarItem: null,
			menuActive: false,
			searchClick: false,
			themeColors: [
				{name: "Blue", file: "blue", color: "#0f97c7"},
				{name: "Blue Grey", file: "bluegrey", color: "#578697"},
				{name: "Brown", file: "brown", color: "#97664A"},
				{name: "Cyan", file: "cyan", color: "#1BA7AF"},
				{name: "Deep Orange", file: "deeporange", color: "#F96F43"},
				{name: "Deep Purple", file: "deeppurple", color: "#6952EC"},
				{name: "Green", file: "green", color: "#10B163"},
				{name: "Teal", file: "teal", color: "#4EA279"},
				{name: "Indigo", file: "indigo", color: "#435AD8"},
				{name: "Lime", file: "lime", color: "#A5B600"},
				{name: "Magenta", file: "magenta", color: "#B944D6"},
				{name: "Orange", file: "orange", color: "#E2841A"},
				{name: "Pink", file: "pink", color: "#E93A76"},
				{name: "Purple", file: "purple", color: "#9643F9"},
				{name: "Rose", file: "rose", color: "#AB5353"},
			],
			menu: []
		}
	},
  created() {
      // console.log('configurar tema')
      this.onLayoutColorChange('mauve')
      this.onThemeChange('blue')

  },
  watch: {
		$route() {
			this.menuActive = false;
			this.$toast.removeAllGroups();
		}
  },
  methods: {

    onUserDataChanged(event){
      // console.log('onUserDataChanged app')
      // console.log(event)
      this.userData = event

      if (this.userData.tipo === 'admin') {
        this.menu = [
          {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
              {label: 'Vendas', icon: 'pi pi-desktop', to: '/'},
              {label: 'Analítico', icon: 'pi pi-chart-bar', to: '/analytics'},
            ],
          },


          {
            label: 'Favoritos', icon: 'pi pi-star',
            items: [
              {label: 'Pedidos', icon: 'pi pi-shopping-cart', to: '/pedidos'},
              {label: 'Financeiro', icon: 'pi pi-sitemap', to: '/financeiro'},
              {label: 'Clientes', icon: 'pi pi-users', to: '/clientes'},
            ]
          },
          {
            label: 'Cadastros', icon: 'pi pi-sitemap',
            items: [
              {label: 'Metas', icon: 'pi pi-chart-bar', to: '/metas'},
              {label: 'Condições', icon: 'pi pi-credit-card', to: '/condicoes'},
              {label: 'Formas de Pagamento', icon: 'pi pi-list', to: '/formas'},
              {label: 'Tipos de Operação', icon: 'pi pi-ticket', to: '/pedtipos'},
              {label: 'Produtos', icon: 'pi pi-box', to: '/produtos'},

              {label: 'Faturamento', icon: 'pi pi-shopping-cart', to: '/vendas'},
              // {label: 'Avaliação', icon: 'pi pi-comments', to: '/avaliacao'},
              {label: 'Visita', icon: 'pi pi-car', to: '/visitas'},
              {label: 'Calendário', icon: 'pi pi-calendar', to: '/calendario'},
            ]
          },
          {
            label: 'Financeiro', icon: 'pi pi-money-bill',
            items: [
              {label: 'Financeiro', icon: 'pi pi-dollar', to: '/financeiro'},
              {label: 'Receitas', icon: 'pi pi-plus', to: '/receitas'},
              {label: 'Despesas', icon: 'pi pi-minus', to: '/despesas'},
              {label: 'Comissões', icon: 'pi pi-money-bill', to: '/comissoes'},
            ]
          },
          {
            label: 'Relatórios', icon: 'pi pi-file-o',
            items: [
              {label: 'Pedidos em Aberto', icon: 'pi pi-shopping-cart', to: '/pedidosemaberto'},
              {label: 'Pedidos por Cliente', icon: 'pi pi-shopping-cart', to: '/pedidosporcliente'},
              {label: 'Pedidos por Período', icon: 'pi pi-shopping-cart', to: '/pedidosporperiodo'},
              {label: 'Carteira de Clientes', icon: 'pi pi-users', to: '/carteiraclientes'},
              // {label: 'Comissões', icon: 'pi pi-money-bill', to: '/apuracomiss'},
              {label: 'Arquivos Protheus', icon: 'pi pi-list', to: '/metasdata'},

              // {label: 'Pendente Configuração',
              //   icon: 'pi pi-align-left',
              //   items: [
              //     {label: 'Pedidos', icon: 'pi pi-shopping-cart', items: [
              //         {label: 'Pedidos por Cidade', icon: 'pi pi-shopping-cart', to: '/acessorestrito'},
              //         {label: 'Pedidos por Estado', icon: 'pi pi-shopping-cart', to: '/acessorestrito'},
              //         {label: 'Pedidos por Produto', icon: 'pi pi-shopping-cart', to: '/acessorestrito'},
              //         {label: 'Pedidos por Grupo de Produto', icon: 'pi pi-shopping-cart', to: '/acessorestrito'},
              //         {label: 'Maiores Descontos Período', icon: 'pi pi-shopping-cart', to: '/acessorestrito'},
              //       ]},
              //     {label: 'Clientes', icon: 'pi pi-users', items: [
              //         {label: 'Clientes por Cidade', icon: 'pi pi-users', to: '/acessorestrito'},
              //         {label: 'Clientes por Estado', icon: 'pi pi-users', to: '/acessorestrito'},
              //         {label: 'Clientes por Produto', icon: 'pi pi-users', to: '/acessorestrito'},
              //         {label: 'Clientes por Categoria', icon: 'pi pi-users', to: '/acessorestrito'},
              //         {label: 'Maiores Clientes Período', icon: 'pi pi-users', to: '/acessorestrito'},
              //         {label: 'Maiores Clientes Período', icon: 'pi pi-users', to: '/acessorestrito'},
              //       ]},
              //     {label: 'Produtos', icon: 'pi pi-box', items: [
              //         {label: 'Produtos por Cidade', icon: 'pi pi-box', to: '/acessorestrito'},
              //         {label: 'Produtos por Estado', icon: 'pi pi-box', to: '/acessorestrito'},
              //         {label: 'Produtos por Grupo', icon: 'pi pi-box', to: '/acessorestrito'},
              //         {label: 'Maiores Produtos Período', icon: 'pi pi-box', to: '/acessorestrito'},
              //         {label: 'Maiores Grupos de Produtos Período', icon: 'pi pi-box', to: '/acessorestrito'},
              //       ]},
              //
              //
              //
              //   ]}

            ]
          },


          {
            label: 'Configurações', icon: 'pi pi-cog',
            items: [
              {label: 'Bases de Cálculo', icon: 'pi pi-book', to: '/acessorestrito'},
              {label: 'Kits Personalizados', icon: 'pi pi-list', to: '/acessorestrito'},
              {label: 'Financiamento', icon: 'pi pi-money-bill', to: '/acessorestrito'},
              {label: 'Usuários', icon: 'pi pi-users', to: '/usuarios'},
            ]
          },

        ]
      } else{
        this.menu = [
          {
            label: 'Dashboard', icon: 'pi pi-home',
            items: [
              {label: 'Vendas', icon: 'pi pi-desktop', to: '/'},
              {label: 'Analítico', icon: 'pi pi-chart-bar', to: '/analytics'},
            ],
          },


          {
            label: 'Favoritos', icon: 'pi pi-star',
            items: [
              {label: 'Leads', icon: 'pi pi-id-card', to: '/leads'},
              {label: 'Orçamentos', icon: 'pi pi-eye', to: '/orcamentos'},
            ]
          },
          {
            label: 'Projeto', icon: 'pi pi-sitemap',
            items: [
              {label: 'Vendas', icon: 'pi pi-shopping-cart', to: '/vendas'},
              // {label: 'Avaliação', icon: 'pi pi-comments', to: '/avaliacao'},
              {label: 'Visita', icon: 'pi pi-car', to: '/visitas'},
              // {label: 'Projeto', icon: 'pi pi-sitemap', to: '/projetos'},
              // {label: 'Obra', icon: 'pi pi-briefcase', to: '/obras'},
              {label: 'Calendário', icon: 'pi pi-calendar', to: '/calendario'},
            ]
          },
          {
            label: 'Financeiro', icon: 'pi pi-money-bill',
            items: [
              {label: 'Financeiro', icon: 'pi pi-dollar', to: '/financeiro'},
              {label: 'Receitas', icon: 'pi pi-plus', to: '/receitas'},
              {label: 'Despesas', icon: 'pi pi-minus', to: '/despesas'},
              {label: 'Comissões', icon: 'pi pi-money-bill', to: '/comissoes'},
            ]
          },
          {
            label: 'Relatórios', icon: 'pi pi-file-o',
            items: [
                {label: 'Pedidos em Aberto', icon: 'pi pi-shopping-cart', to: '/pedidosemaberto'},
                {label: 'Pedidos por Cliente', icon: 'pi pi-shopping-cart', to: '/pedidosporcliente'},
                {label: 'Pedidos por Período', icon: 'pi pi-shopping-cart', to: '/pedidosporperiodo'},
                {label: 'Carteira de Clientes', icon: 'pi pi-users', to: '/carteiraclientes'},
                {label: 'Comissões', icon: 'pi pi-money-bill', to: '/apuracomiss'},
                {label: 'Arquivos Protheus', icon: 'pi pi-list', to: '/metasdata'},

                // {label: 'Pedido por Cliente', icon: 'pi pi-shopping-cart', to: '/pedidosporcliente'},
              // {label: 'Pedido por Período', icon: 'pi pi-shopping-cart', to: '/pedidosporperiodo'},
              // {label: 'Pendente Configuração',
              //   icon: 'pi pi-align-left',
              //   items: [
              //     {label: 'Pedidos', icon: 'pi pi-shopping-cart', items: [
              //         {label: 'Pedidos por Cidade', icon: 'pi pi-shopping-cart', to: '/acessorestrito'},
              //         {label: 'Pedidos por Estado', icon: 'pi pi-shopping-cart', to: '/acessorestrito'},
              //         {label: 'Pedidos por Produto', icon: 'pi pi-shopping-cart', to: '/acessorestrito'},
              //         {label: 'Pedidos por Grupo de Produto', icon: 'pi pi-shopping-cart', to: '/acessorestrito'},
              //         {label: 'Maiores Descontos Período', icon: 'pi pi-shopping-cart', to: '/acessorestrito'},
              //       ]},
              //     {label: 'Clientes', icon: 'pi pi-users', items: [
              //         {label: 'Clientes por Cidade', icon: 'pi pi-users', to: '/acessorestrito'},
              //         {label: 'Clientes por Estado', icon: 'pi pi-users', to: '/acessorestrito'},
              //         {label: 'Clientes por Produto', icon: 'pi pi-users', to: '/acessorestrito'},
              //         {label: 'Clientes por Categoria', icon: 'pi pi-users', to: '/acessorestrito'},
              //         {label: 'Maiores Clientes Período', icon: 'pi pi-users', to: '/acessorestrito'},
              //         {label: 'Maiores Clientes Período', icon: 'pi pi-users', to: '/acessorestrito'},
              //       ]},
              //     {label: 'Produtos', icon: 'pi pi-box', items: [
              //         {label: 'Produtos por Cidade', icon: 'pi pi-box', to: '/acessorestrito'},
              //         {label: 'Produtos por Estado', icon: 'pi pi-box', to: '/acessorestrito'},
              //         {label: 'Produtos por Grupo', icon: 'pi pi-box', to: '/acessorestrito'},
              //         {label: 'Maiores Produtos Período', icon: 'pi pi-box', to: '/acessorestrito'},
              //         {label: 'Maiores Grupos de Produtos Período', icon: 'pi pi-box', to: '/acessorestrito'},
              //       ]},
              //
              //
              //
              //   ]}
            ]
          },



        ]
      }

    },

		onDocumentClick() {
			if (!this.topbarItemClick && !this.searchClick) {
				this.activeTopbarItem = null;
			}

			if (!this.topbarMenuClick) {
				this.topbarMenuActive = false;
			}

			if (!this.menuClick) {
				if (this.isSlim()) {
					this.menuActive = false;
                }

				if (this.staticMenuMobileActive) {
					this.hideOverlayMenu();
				}

				EventBus.emit('reset-active-index');
				this.unblockBodyScroll();
			}

			this.searchClick = false;
			this.topbarItemClick = false;
			this.topbarMenuClick = false;
			this.menuClick = false;
		},
		onMenuButtonClick(event) {
			this.menuClick = true;
			this.topbarMenuActive = false;

			if (this.isDesktop())
				this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
			else {
				this.staticMenuMobileActive = !this.staticMenuMobileActive;
				if (this.staticMenuMobileActive) {
					this.blockBodyScroll();
				} else {
					this.unblockBodyScroll();
				}
			}

			event.preventDefault();
		},
		onTopbarItemClick(event) {
			this.topbarItemClick = true;


			if (this.activeTopbarItem === event.item)
				this.activeTopbarItem = null;
			else
				this.activeTopbarItem = event.item;

			event.originalEvent.preventDefault();
		},
		onSearchItemClick() {
			this.searchClick = true;
		},
		onTopbarMenuClick() {
			this.topbarMenuClick = true;
			this.topbarMenuActive = true;
		},
		onTopbarSubmenuClick(event) {
			this.topbarMenuClick = true;

			if (!event.item.items) {
				this.topbarMenuActive = false;
				EventBus.emit('reset-active-index');
			}
		},
		onTopbarMenuItemClick(event) {
			this.topbarItemClick = true;

			if (this.activeTopbarItem === event.item)
				this.activeTopbarItem = null;
			else
				this.activeTopbarItem = event.item;

			event.originalEvent.preventDefault();
		},
		onMenuClick() {
			this.menuClick = true;
		},
		onMenuItemClick(event) {
			if (!event.item.items) {
				EventBus.emit('reset-active-index');
				this.hideOverlayMenu();
			}
			if (!event.item.items && this.isSlim()) {
				this.menuActive = false;
			}
		},
		onRootMenuItemClick() {
			this.menuActive = !this.menuActive;
		},
		isTablet() {
			const width = window.innerWidth;
			return width <= 1024 && width > 640;
		},
		isDesktop() {
			return window.innerWidth > 896;
		},
		isMobile() {
			return window.innerWidth < 1025;
		},
		isSlim() {
			return this.menuMode === 'slim';
		},
		onMenuModeChange(menuMode) {
			this.menuMode = menuMode;
			this.staticMenuDesktopInactive = false;
		},
		onThemeChange(theme) {
			this.theme = theme;
      // console.log('onThemeChange');
      // console.log(theme);

			const themeLink = document.getElementById('theme-css');
			const href = 'theme/' + theme + '/theme-' + this.$appState.colorScheme + '.css';

			this.replaceLink(themeLink, href);
		},
		onLayoutColorChange(layout) {
			this.tempLayoutColor = layout;
			this.layoutColor = layout;
      // console.log('onLayoutColorChange');
      // console.log(layout);

			this.changeStyleSheetUrl('layout-css', layout, 'layout');
		},
		onColorSchemeChange(newColorScheme) {
			this.$appState.colorScheme = newColorScheme;
			this.layoutColor = newColorScheme === 'dark' ? newColorScheme : this.tempLayoutColor;

			this.changeStyleSheetUrl('layout-css', this.layoutColor, 'layout');
			this.changeStyleSheetUrl('theme-css', newColorScheme, 'theme');
		},
		changeStyleSheetUrl(id, value, prefix) {
			let element = document.getElementById(id);
			let urlTokens = element.getAttribute('href').split('/');
			urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
			let newURL = urlTokens.join('/');

			this.replaceLink(element, newURL);
		},
		replaceLink(linkElement, href) {
			const id = linkElement.getAttribute('id');
			const cloneLinkElement = linkElement.cloneNode(true);

			cloneLinkElement.setAttribute('href', href);
			cloneLinkElement.setAttribute('id', id + '-clone');

			linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

			cloneLinkElement.addEventListener('load', () => {
				linkElement.remove();
				cloneLinkElement.setAttribute('id', id);
			});
		},
		blockBodyScroll() {
			this.addClass(document.body, 'blocked-scroll');
		},
		unblockBodyScroll() {
			this.removeClass(document.body, 'blocked-scroll');
		},
		hideOverlayMenu() {
			this.staticMenuMobileActive = false;
		},
		addClass(element, className) {
			if (element.classList)
				element.classList.add(className);
			else
				element.className += ' ' + className;
		},
		removeClass(element, className) {
			if (element.classList)
				element.classList.remove(className);
			else
				element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
		}
	},
	computed: {
		containerClass() {
			return ['layout-wrapper', {
				'layout-slim': this.menuMode === 'slim',
				'layout-static': this.menuMode === 'static',
				'layout-mobile-active': this.staticMenuMobileActive,
				'layout-static-inactive': this.staticMenuDesktopInactive && this.menuMode === 'static',
				'p-input-filled': this.$primevue.config.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
			}];
		}
	},
	components: {
		'AppTopBar': AppTopBar,
		'AppMenu': AppMenu,
		'AppConfig': AppConfig,
		'AppFooter': AppFooter
	}
}
</script>
