<template>
	<div class="layout-footer">
    <div class="footer-left">
        <img :src="'layout/images/logo-' + (layoutColor === 'white' ? 'dark' : 'white') + '.png'" alt="logo" />
<!--        <span class="app-name ml-0">Nesher</span>-->
        <span class="ml-2">Plataforma para Representantes</span>
    </div>
    <div class="footer-right">

      <a class="text-white" href="https://www.mobagile.com.br"><span class="">©MobAgile Sistemas</span></a>

    </div>
</div>

</template>

<script>
	export default {
		name: "AppFooter",
		props: ['layoutColor']
	}
</script>

