<template>
	<div class="layout-sidebar" @click="onMenuClick">
		<div class="layout-menu-container">
			<AppSubmenu :items="model" :menuMode="menuMode" :menuActive="active" :mobileMenuActive="mobileMenuActive" :root="true" :parentMenuItemActive="true"
				@root-menuitem-click="onRootMenuitemClick" @menuitem-click="onMenuitemClick"/>
		</div>
	</div>
</template>

<script>
import AppSubmenu from './AppSubmenu';

export default {
	emits: ['menu-click', 'root-menuitem-click', 'menuitem-click'],
	props: {
		model: Array,
		menuMode: String,
		active: Boolean,
		mobileMenuActive: Boolean
	},
	methods: {
		onMenuClick(event) {
            this.$emit('menu-click', event);
        },
		onRootMenuitemClick(event) {
			this.$emit('root-menuitem-click', event);
		},
		onMenuitemClick(event) {
			this.$emit('menuitem-click', event);
		}
	},
	components: {
		'AppSubmenu': AppSubmenu
	}
}
</script>

<style scoped>

</style>
