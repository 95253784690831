<template>
	<div class="layout-topbar">
		<div class="layout-topbar-left">
			<router-link to="/" class="app-name">
				<img alt="main logo" class="app-logo" :src="'layout/images/logo-'+ (layoutColor === 'white' ? 'dark' : 'white') + '.png'">
<!--				<span>Nesher</span>-->
			</router-link>

			<a href="#" class="menu-button" @click="onMenuButtonClick">
				<div class="menu-button-icon"></div>
			</a>

			<ul class="layout-topbar-main-menu">
				<li>
					<router-link to="/" exact-active-class="topbar-active-menuitem-routerlink">Vendas
						<span class="active-bottom"></span>
					</router-link>
				</li>
				<li>
					<router-link to="/clientes" active-class="topbar-active-menuitem-routerlink">Clientes
						<span class="active-bottom"></span>
					</router-link>
				</li>
				<li>
					<router-link to="/pedidos" active-class="topbar-active-menuitem-routerlink">Pedidos
						<span class="active-bottom"></span>
					</router-link>
				</li>

                <li>
                    <router-link to="/ajuda" exact-active-class="topbar-active-menuitem-routerlink">Ajuda
                        <span class="active-bottom"></span>
                    </router-link>
                </li>
				<li :class="['layout-topbar-menu', {'topbar-active-menuitem': topbarMenuActive}]">
					<a v-if="!menu[0].to" :style="menu[0].style" :class="[menu[0].class, 'p-ripple', {'p-disabled': menu[0].disabled}]" :target="menu[0].target"
						@click="onMenuItemClick($event,menu[0])" v-ripple>
						<i :class="['layout-menuitem-icon', menu[0].icon]"></i>
						<span class="layout-menuitem-text">{{menu[0].label}}</span>
						<i v-if="menu[0].items" class="pi pi-fw pi-angle-down layout-topbar-submenu-toggler"></i>
					</a>
					<AppTopbarMenu :items="menu[0].items" v-if="topbarMenuActive" :topbarMenuActive="topbarMenuActive" :parentMenuItemActive="true"
						@topbar-submenu-click="onTopbarMenuClick($event)" @topbar-submenuitem-click="onTopbarSubmenuItemClick($event)"></AppTopbarMenu>
				</li>
			</ul>
		</div>

		<div class="layout-topbar-right">

			<ul class="layout-topbar-actions">
				<li :class="['search-item', {'active-topmenuitem': activeTopbarItem === 'search'}]">
					<a tabindex="0" @click="onTopbarItemClick($event, 'search')">
						<i class="pi pi-search"></i>
					</a>

					<div class="search-input-wrapper">
						<span class="p-input-icon-right">
							<InputText ref="input1" type="text" placeholder="Pesquisar..." @click="$emit('search-item-click');"/>
							<i class="pi pi-search"></i>
						</span>
					</div>

					<ul class="fadeInDown">
						<div class="search-input-wrapper p-fluid" style="width: 100%">
							<span class="p-input-icon-right">
								<InputText ref="input2" type="text" placeholder="Pesquisar..." @click="$emit('search-item-click');" />
								<i class="pi pi-search"></i>
							</span>
						</div>
					</ul>
				</li>

				<li :class="['profile-item', {'active-topmenuitem fadeInDown': activeTopbarItem === 'profile'}]">

          <Skeleton width="140px" height="50px" v-if="loadingUser"></Skeleton>


          <div class="profile-container" @click="onTopbarItemClick($event, 'profile')" v-if="!loadingUser">
						<a href="#">{{ userData != null ? formatSigla(userData.name) : '' }}</a>
						<div class="name-container">
							<span class="name">{{ userData != null ? formatName(userData.name) : '' }}</span>
							<span class="job">{{ userData != null ? userData.tipo : '' }}</span>
						</div>
						<i class="pi pi-angle-down"></i>
					</div>

					<ul class="profile-menu fadeInDown">
						<li>

              <router-link :to="'/usuariodetalhe/' + userData.id" v-if="userData">
                <span>Perfil</span>
              </router-link>

						</li>
						<li>

              <router-link to="/alertas" v-if="userData">
                <span>Alertas</span>
              </router-link>

						</li>
						<li>
							<a href="#" @click="logout()">
								<span>Logout</span>
							</a>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import AppTopbarMenu from './AppTopbarMenu';
import UtilsService from '@/service/UtilsService';

export default {
	emits: ['menubutton-click', 'topbar-menu-click', 'topbar-item-click', 'topbar-submenu-click', 'search-item-click'],
	props: ['activeTopbarItem', 'topbarMenuActive', 'layoutColor'],
	data() {
		return {
			menu: [
				{
					label: 'Favoritos',
          items: [

            {label: 'Leads', icon: 'pi pi-users', to: '/leads'},
            {label: 'Financeiro', icon: 'pi pi-sitemap', to: '/financeiro'},
            {label: 'Produtos', icon: 'pi pi-box', to: '/produtos'},
          ]
				}
			],
      utilsService: null,
      userData: null,
      loadingUser: true,
		}
	},
  created() {
    this.utilsService = new UtilsService();
  },



  methods: {
    formatName(name){
      let newname = name;
      if (name.indexOf(' ') > 0){
        newname = name.substr(0, name.indexOf(' '))
      }
      return newname
    },

    formatSigla(name){
      let newsigla = ''

      if (name.indexOf(' ') > 0){
        //console.log(name.indexOf(' '))
        newsigla = name.substr(0, 1) + name.substr(name.indexOf(' ') + 1, 1).toUpperCase()
      }else{
        newsigla = name.substr(0, 2).toUpperCase()
      }
      return newsigla
    },

		onMenuButtonClick(event) {
			this.$emit('menubutton-click', event);
		},
    logout(){
      this.$store.dispatch('logout');
      console.log('logout')
    },
		onTopbarItemClick(event, item) {
			if(item === 'search' && this.$refs.input1) {
				setTimeout(() => {
					this.$refs.input1.$el.focus();
					this.$refs.input2.$el.focus();
				}, 400);
			}

			this.$emit('topbar-item-click', {originalEvent: event, item: item});
			event.preventDefault();
		},
		onTopbarMenuClick(event) {
			this.$emit('topbar-submenu-click', event);
		},
		onTopbarSubItemClick(event) {
      console.log('clicou_top_bar')
      console.log(event)
      // this.$router.push({url: 'pages/landing.html', target: '_blank'})
			//event.preventDefault();
		},
		onTopbarSubmenuItemClick(event) {
			this.$emit('topbar-submenuitem-click', event);
		},
		onMenuItemClick(event, item) {
			if (item.disabled) {
				event.preventDefault();
				return;
			}

			//execute command
			if (item.command) {
                item.command({originalEvent: event, item: item});
                event.preventDefault();
			}

            if (item.items && this.topbarMenuActive) {
                event.preventDefault();
            }
			else {
				this.$emit('topbar-menu-click', event);
				event.preventDefault();
			}
		}
	},
  mounted() {
    // console.log('appTopBar mounted')
    // console.log(this.$store.getters.user)

    this.utilsService.getssuserlog().then((data) => {
      // console.log('appTopBar mounted')
      // console.log(data)
      // this.loading1 = false
      // this.clientes = data[0]
      this.userData = data
      this.$emit('user-data-changed', data);
      this.loadingUser = false
    })


  },
	components: {
		AppTopbarMenu
	}
}
</script>
