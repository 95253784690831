import api from '../axios'
import router from '../router'
// import {useToast} from "primevue/usetoast";

export default ({
    state: {
        authenticated: false,
        user: null,
        token: null
    },
    mutations: {
        authenticateUser(state, data) {
            state.authenticated = true;
            state.token = data.token;
            state.user = data.user;
        },

        unauthenticateUser(state) {
            state.authenticated = false;
            state.token = null;
            state.user = null
        }
    },
    actions: {
        async logout({ commit }) {
            await api.post('api/logout');
            localStorage.removeItem("token");
            commit('unauthenticateUser');
            router.push('/login');
        },

        async login({ commit }, credentials) {

            console.log('login na store')
            console.log(credentials)

            // await api.get('sanctum/csrf-cookie');
            // const res = await api.post('login', credentials);
            //
            // router.push('/');



            api.get('/sanctum/csrf-cookie').then(response => {
              console.log('response csrf-cookie')
              console.log(response)

                //useToast.add({severity:'success', summary: 'Sucesso2222', detail: 'Login realizado com sucesso.'});

              api.post('api/login', credentials).then(async resuser => {
                commit('authenticateUser', resuser.data)
                // console.log('response login')
                // console.log(resuser.data)
                // console.log('User signed in!');
                // this.$toast.add({severity: 'success', summary: 'Sucesso', detail: 'Login efetuado com sucesso.'});

                  //this.useToast.add({severity:'success', summary: 'Sucesso', detail: 'Login realizado com sucesso.'});
                router.push('/')
                localStorage.setItem('token', JSON.stringify(resuser.data))

              }).catch(error => {
                console.log('error')
                console.log(error)
                //this.useToast.add({severity:'error', summary: 'Erro', detail: 'Os dados para acesso não são válidos.'});
              });
            });

        }
    },
    getters: {
        isAuthenticated() {
            console.log('isAuthenticado')
            if (localStorage.getItem('token')){
                return true
            }else{
                return false
            }
        },
        user(state) {
            return state.user;
        },
        userToken(state) {
            return state.token;
        }
    },
    modules: {}
})
